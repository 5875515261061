let shapes = [{
    "shape_id": 1,
    "type": "shape",
    "coordinates": [[{"lat": 47.94976405693712, "lng": 0.2070558071136475}, {
        "lat": 47.949426314751214,
        "lng": 0.20706117153167725
    }, {"lat": 47.94942990776481, "lng": 0.2071630954742432}, {"lat": 47.94977124291711, "lng": 0.2071577310562134}]],
    "name": "Tribune 22",
    "logistics": "Sièges, Personnel",
    "surface": "100",
    "description": "Tribune accueillant les spectateurs des 24H du Mans.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "Billetterie",
    "category": "tribunes"
}, {
    "shape_id": 2,
    "type": "shape",
    "coordinates": [[{"lat": 47.94992574124491, "lng": 0.20713090896606448}, {
        "lat": 47.950202399887125,
        "lng": 0.20714163780212402
    }, {"lat": 47.95019880692722, "lng": 0.20698070526123047}, {
        "lat": 47.949929334223775,
        "lng": 0.20698070526123047
    }]],
    "name": "Tribune 21",
    "logistics": "Sièges, Personnel",
    "surface": "100",
    "description": "Tribune accueillant les spectateurs des 24H du Mans.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "Billetterie",
    "category": "tribunes"
}, {
    "shape_id": 3,
    "type": "shape",
    "coordinates": [[{"lat": 47.9502742590324, "lng": 0.20713627338409427}, {
        "lat": 47.950784456090794,
        "lng": 0.2071470022201538
    }, {"lat": 47.95079523484753, "lng": 0.20698606967926028}, {
        "lat": 47.950256294255446,
        "lng": 0.20698070526123047
    }]],
    "name": "Tribune 20",
    "logistics": "Sièges, Personnel",
    "surface": "100",
    "description": "Tribune accueillant les spectateurs des 24H du Mans.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "Billetterie",
    "category": "tribunes"
}, {
    "shape_id": 4,
    "type": "shape",
    "coordinates": [[{"lat": 47.950881464820704, "lng": 0.20715236663818362}, {
        "lat": 47.951398841638124,
        "lng": 0.2071630954742432
    }, {"lat": 47.95139524876142, "lng": 0.20700216293334964}, {
        "lat": 47.950874278995094,
        "lng": 0.20699679851531985
    }]],
    "name": "Tribune 19",
    "logistics": "Sièges, Personnel",
    "surface": "100",
    "description": "Tribune accueillant les spectateurs des 24H du Mans.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "Billetterie",
    "category": "tribunes"
}, {
    "shape_id": 5,
    "type": "shape",
    "coordinates": [[{"lat": 47.95151740642928, "lng": 0.20721137523651126}, {
        "lat": 47.95151381356082,
        "lng": 0.20710945129394534
    }, {"lat": 47.95147069911979, "lng": 0.20709872245788574}, {
        "lat": 47.951477884862456,
        "lng": 0.20700216293334964
    }, {"lat": 47.95151022069211, "lng": 0.206943154335022}, {
        "lat": 47.95157129942622,
        "lng": 0.2069324254989624
    }, {"lat": 47.952131783262786, "lng": 0.2069377899169922}, {"lat": 47.952131783262786, "lng": 0.2072328329086304}]],
    "name": "Tribune 18",
    "logistics": "Sièges, Personnel",
    "surface": "125",
    "description": "Tribune accueillant les spectateurs des 24H du Mans.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "Billetterie",
    "category": "tribunes"
}, {
    "shape_id": 6,
    "type": "shape",
    "coordinates": [[{"lat": 47.95255214215088, "lng": 0.20724356174468997}, {
        "lat": 47.952164118683264,
        "lng": 0.20722746849060059
    }, {"lat": 47.95216771150653, "lng": 0.20706653594970706}, {
        "lat": 47.95223597510079,
        "lng": 0.20707726478576663
    }, {"lat": 47.952573698924745, "lng": 0.20707726478576663}]],
    "name": "Tribune 17",
    "logistics": "Sièges, Personnel",
    "surface": "100",
    "description": "Tribune accueillant les spectateurs des 24H du Mans.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "Billetterie",
    "category": "tribunes"
}, {
    "shape_id": 7,
    "type": "shape",
    "coordinates": [[{"lat": 47.95263836919244, "lng": 0.20729184150695804}, {
        "lat": 47.95299764698358,
        "lng": 0.2073025703430176
    }, {"lat": 47.95299764698358, "lng": 0.20709335803985598}, {"lat": 47.95263836919244, "lng": 0.20707726478576663}]],
    "name": "Tribune 16",
    "logistics": "Sièges, Personnel",
    "surface": "100",
    "description": "Tribune accueillant les spectateurs des 24H du Mans.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "Billetterie",
    "category": "tribunes"
}, {
    "shape_id": 8,
    "type": "shape",
    "coordinates": [[{"lat": 47.95307309500239, "lng": 0.20731866359710696}, {
        "lat": 47.95343596250611,
        "lng": 0.20731866359710696
    }, {"lat": 47.953432369771036, "lng": 0.2071201801300049}, {
        "lat": 47.953076687762426,
        "lng": 0.20710408687591553
    }]],
    "name": "Tribune 15",
    "logistics": "Sièges, Personnel",
    "surface": "100",
    "description": "Tribune accueillant les spectateurs des 24H du Mans.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "Billetterie",
    "category": "tribunes"
}, {
    "shape_id": 9,
    "type": "shape",
    "coordinates": [[{"lat": 47.953489853502205, "lng": 0.20732939243316653}, {
        "lat": 47.95385271807936,
        "lng": 0.2073776721954346
    }, {"lat": 47.9538742743107, "lng": 0.20718991756439212}, {"lat": 47.9535078171551, "lng": 0.2071255445480347}]],
    "name": "Tribune 14",
    "logistics": "Sièges, Personnel",
    "surface": "100",
    "description": "Tribune accueillant les spectateurs des 24H du Mans.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "Billetterie",
    "category": "tribunes"
}, {
    "shape_id": 10,
    "type": "shape",
    "coordinates": [[{"lat": 47.95392816484968, "lng": 0.2073776721954346}, {
        "lat": 47.95415450449967,
        "lng": 0.2074152231216431
    }, {"lat": 47.95416887523762, "lng": 0.20727038383483887}, {"lat": 47.95393894295072, "lng": 0.20723819732666018}]],
    "name": "Tribune 13",
    "logistics": "Sièges, Personnel",
    "surface": "85",
    "description": "Tribune accueillant les spectateurs des 24H du Mans.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "Billetterie",
    "category": "tribunes"
}, {
    "shape_id": 11,
    "type": "shape",
    "coordinates": [[{"lat": 47.95432336041821, "lng": 0.2074152231216431}, {
        "lat": 47.95468262649409,
        "lng": 0.20746350288391116
    }, {"lat": 47.95470058973232, "lng": 0.20726501941680908}, {
        "lat": 47.954330545764215,
        "lng": 0.20721673965454104
    }]],
    "name": "Tribune 12",
    "logistics": "Sièges, Personnel",
    "surface": "100",
    "description": "Tribune accueillant les spectateurs des 24H du Mans.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "Billetterie",
    "category": "tribunes"
}, {
    "shape_id": 12,
    "type": "shape",
    "coordinates": [[{"lat": 47.95518918741696, "lng": 0.20739912986755374}, {
        "lat": 47.9551784095767,
        "lng": 0.20760834217071536
    }, {"lat": 47.954797591110804, "lng": 0.20753860473632815}, {"lat": 47.9548227395866, "lng": 0.20733475685119632}]],
    "name": "Tribune 11",
    "logistics": "Sièges, Personnel",
    "surface": "100",
    "description": "Tribune accueillant les spectateurs des 24H du Mans.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "Billetterie",
    "category": "tribunes"
}, {
    "shape_id": 13,
    "type": "shape",
    "coordinates": [[{"lat": 47.95764288386666, "lng": 0.21063923835754397}, {
        "lat": 47.95727286097019,
        "lng": 0.21007597446441653
    }, {"lat": 47.957197419083556, "lng": 0.21018862724304202}, {
        "lat": 47.95757103496789,
        "lng": 0.21075189113616946
    }]],
    "name": "Tribune 5",
    "logistics": "Sièges, Personnel",
    "surface": "100",
    "description": "Tribune accueillant les spectateurs des 24H du Mans.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "Billetterie",
    "category": "tribunes"
}, {
    "shape_id": 14,
    "type": "shape",
    "coordinates": [[{"lat": 47.95239405886763, "lng": 0.20801067352294922}, {
        "lat": 47.952383280444366,
        "lng": 0.20823597908020022
    }, {"lat": 47.95195573450744, "lng": 0.20821988582611084}, {
        "lat": 47.95195214166944,
        "lng": 0.20867586135864258
    }, {"lat": 47.95186591348283, "lng": 0.20869195461273196}, {
        "lat": 47.951887470542964,
        "lng": 0.2082091569900513
    }, {"lat": 47.951499442084476, "lng": 0.20819842815399173}, {
        "lat": 47.9514886634746,
        "lng": 0.20869195461273196
    }, {"lat": 47.95140962026675, "lng": 0.20868659019470218}, {
        "lat": 47.95141680601792,
        "lng": 0.20819306373596194
    }, {"lat": 47.951039552731125, "lng": 0.20818233489990234}, {
        "lat": 47.951021588220215,
        "lng": 0.20865976810455325
    }, {"lat": 47.95093895138954, "lng": 0.20866513252258304}, {
        "lat": 47.95096050883636,
        "lng": 0.20816624164581302
    }, {"lat": 47.950565287549004, "lng": 0.20816624164581302}, {
        "lat": 47.9505545087443,
        "lng": 0.2086436748504639
    }, {"lat": 47.95046468528417, "lng": 0.20864903926849368}, {
        "lat": 47.95048264998868,
        "lng": 0.20815551280975345
    }, {"lat": 47.95027785198703, "lng": 0.20814478397369388}, {"lat": 47.9502742590324, "lng": 0.20792484283447268}]],
    "name": "Tribune 34",
    "logistics": "Sièges, Personnel",
    "surface": "780",
    "description": "Tribune accueillant les spectateurs des 24H du Mans.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "Billetterie",
    "category": "tribunes"
}, {
    "shape_id": 15,
    "type": "shape",
    "coordinates": [[{"lat": 47.94793519254747, "lng": 0.20727038383483887}, {
        "lat": 47.9473135795019,
        "lng": 0.20756006240844727
    }, {"lat": 47.947274054720204, "lng": 0.20742058753967288}, {
        "lat": 47.94791004071968,
        "lng": 0.20713627338409427
    }]],
    "name": "Tribune 23",
    "logistics": "Sièges, Personnel",
    "surface": "200",
    "description": "Tribune accueillant les spectateurs des 24H du Mans.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "Billetterie",
    "category": "tribunes"
}, {
    "shape_id": 16,
    "type": "shape",
    "coordinates": [[{"lat": 47.9589806266146, "lng": 0.20596095988752788}, {
        "lat": 47.95879309513009,
        "lng": 0.20747891777187724
    }, {"lat": 47.958731989917965, "lng": 0.20754863683384087}, {
        "lat": 47.95779743060979,
        "lng": 0.20747891777187724
    }, {"lat": 47.95770037925118, "lng": 0.20736093166699113}, {
        "lat": 47.95726184861711,
        "lng": 0.20598263944187956
    }, {"lat": 47.95734452270986, "lng": 0.20539270891748898}, {"lat": 47.95892608857722, "lng": 0.20585929033223316}]],
    "name": "Parking Nord-Ouest",
    "logistics": "Places, Bornes de recharge",
    "surface": "500",
    "description": "Parking pouvant accueillir un grand nombre de véhicules de tout genre, sauf les caravanes. ",
    "provider": "0b7956e6-1262-49f7-aaab-c5ab60d16cba",
    "service": "",
    "category": "parkingsRouge"
}, {
    "shape_id": 17,
    "type": "shape",
    "coordinates": [[{"lat": 47.96025362073041, "lng": 0.20821267003390712}, {
        "lat": 47.95988699873832,
        "lng": 0.20815903998622434
    }, {"lat": 47.95942692040045, "lng": 0.20807323190993993}, {
        "lat": 47.95926157874698,
        "lng": 0.20807323190993993
    }, {"lat": 47.959240012405374, "lng": 0.20892058666314386}, {
        "lat": 47.959196879695085,
        "lng": 0.20911365483475391
    }, {"lat": 47.959053103734064, "lng": 0.2092638189682417}, {
        "lat": 47.95861458458174,
        "lng": 0.20917801089195723
    }, {"lat": 47.95837016245511, "lng": 0.20928527098728278}, {
        "lat": 47.958223443327505,
        "lng": 0.20936181719425576
    }, {"lat": 47.958467866148254, "lng": 0.2098981176709236}, {
        "lat": 47.959330525684585,
        "lng": 0.21134612895803875
    }, {"lat": 47.959495867117376, "lng": 0.21160355318685214}, {
        "lat": 47.96020767999338,
        "lng": 0.2127726882260728
    }, {"lat": 47.96050960230179, "lng": 0.21334116673138231}, {
        "lat": 47.96077558001634,
        "lng": 0.21394182326529346
    }, {"lat": 47.96096967099781, "lng": 0.2144244936943185}, {
        "lat": 47.96135136714892,
        "lng": 0.2154434646000514
    }, {"lat": 47.961649127034356, "lng": 0.2163451842975617}, {
        "lat": 47.961663503926,
        "lng": 0.21439305056234037
    }, {"lat": 47.96101653984118, "lng": 0.2129343132657047}, {
        "lat": 47.96068586618006,
        "lng": 0.21212986255066293
    }, {"lat": 47.96051333995138, "lng": 0.21165791813115845}, {
        "lat": 47.96036956765403,
        "lng": 0.21087491943515785
    }, {"lat": 47.96022579495656, "lng": 0.20998466064383162}, {
        "lat": 47.96017547441792,
        "lng": 0.20956634627200987
    }, {"lat": 47.96022579495656, "lng": 0.20862245743300092}]],
    "name": "Parking Nord-Ouest",
    "logistics": "Places, Bornes de recharge",
    "surface": "1100",
    "description": "Parking pouvant accueillir un grand nombre de véhicules de tout genre, sauf les caravanes. ",
    "provider": "0b7956e6-1262-49f7-aaab-c5ab60d16cba",
    "service": "",
    "category": "parkingsRouge"
}, {
    "shape_id": 18,
    "type": "shape",
    "coordinates": [[{"lat": 47.95937402567139, "lng": 0.22103338430037314}, {
        "lat": 47.95928057166267,
        "lng": 0.22118354843386093
    }, {"lat": 47.958475422431725, "lng": 0.22156968477708097}, {
        "lat": 47.95823099964672,
        "lng": 0.22150532871987763
    }, {"lat": 47.95642654860269, "lng": 0.2207437820429581}, {
        "lat": 47.95666379078427,
        "lng": 0.21961755104185968
    }, {"lat": 47.957468968247746, "lng": 0.21998223536603856}, {
        "lat": 47.95767026065304,
        "lng": 0.22003586541372136
    }, {"lat": 47.95785717432714, "lng": 0.22003586541372136}, {
        "lat": 47.958072843110855,
        "lng": 0.219971509356518
    }, {"lat": 47.95859044451866, "lng": 0.21974626315626636}, {
        "lat": 47.9587342217672,
        "lng": 0.2197140851276647
    }, {"lat": 47.95890675393733, "lng": 0.2198427972421113}, {
        "lat": 47.959223061419436,
        "lng": 0.22030401565205532
    }, {"lat": 47.959409969475864, "lng": 0.22061506992855145}, {
        "lat": 47.95948185700983,
        "lng": 0.22076523406203918
    }, {"lat": 47.95941715823377, "lng": 0.22089394617644587}]],
    "name": "Parking Nord-Est",
    "logistics": "Places, Bornes de recharge",
    "surface": "785",
    "description": "Parking pouvant accueillir un grand nombre de véhicules de tout genre, sauf les caravanes. ",
    "provider": "0b7956e6-1262-49f7-aaab-c5ab60d16cba",
    "service": "",
    "category": "parkingsVip"
}, {
    "shape_id": 19,
    "type": "shape",
    "coordinates": [[{"lat": 47.96090003929409, "lng": 0.21860056544588338}, {
        "lat": 47.96141761237733,
        "lng": 0.21997349466623467
    }, {"lat": 47.96187767298694, "lng": 0.221174807734057}, {
        "lat": 47.96141761237733,
        "lng": 0.22229031272559488
    }, {"lat": 47.96088689437265, "lng": 0.22206922560590317}, {
        "lat": 47.96103066523028,
        "lng": 0.22056758427114526
    }, {"lat": 47.96094440276373, "lng": 0.21949498331772954}]],
    "name": "Parking Nord-Est",
    "logistics": "Places, Bornes de recharge",
    "surface": "750",
    "description": "Parking pouvant accueillir un grand nombre de véhicules de tout genre, sauf les caravanes. ",
    "provider": "0b7956e6-1262-49f7-aaab-c5ab60d16cba",
    "service": "",
    "category": "parkingsVip"
}, {
    "shape_id": 20,
    "type": "shape",
    "coordinates": [[{"lat": 47.950060601542816, "lng": 0.20603219235749037}, {
        "lat": 47.94770220830159,
        "lng": 0.20446619496548915
    }, {"lat": 47.94655173352094, "lng": 0.20710479331090603}, {
        "lat": 47.947932300184654,
        "lng": 0.20654704081511716
    }, {"lat": 47.94839248087776, "lng": 0.20637542466258824}, {
        "lat": 47.94883827701693,
        "lng": 0.20637542466258824
    }, {"lat": 47.949269688974205, "lng": 0.20637542466258824}, {"lat": 47.950060601542816, "lng": 0.206332520624426}]],
    "name": "Parking Sud-Ouest",
    "logistics": "Places, Bornes de recharge",
    "surface": "850",
    "description": "Parking pouvant accueillir un grand nombre de véhicules de tout genre, sauf les caravanes. ",
    "provider": "0b7956e6-1262-49f7-aaab-c5ab60d16cba",
    "service": "",
    "category": "parkingsBleu"
}, {
    "shape_id": 21,
    "type": "shape",
    "coordinates": [[{"lat": 47.947572781167196, "lng": 0.2043589348701636}, {
        "lat": 47.944065953471046,
        "lng": 0.2021245444935227
    }, {"lat": 47.94376209667442, "lng": 0.20174156807486468}, {
        "lat": 47.94276973805143,
        "lng": 0.20049735096888013
    }, {"lat": 47.94314367252297, "lng": 0.20455178257277054}, {
        "lat": 47.94328749275333,
        "lng": 0.2057959996787551
    }, {"lat": 47.94337378469945, "lng": 0.20684714861308964}, {
        "lat": 47.94360389585156,
        "lng": 0.20710457284190298
    }, {"lat": 47.943834005979305, "lng": 0.20716892889910635}, {
        "lat": 47.94426545970787,
        "lng": 0.20721183293722858
    }, {"lat": 47.94643705546046, "lng": 0.20710457284190298}]],
    "name": "Parking Sud-Ouest",
    "logistics": "Places, Bornes de recharge",
    "surface": "1700",
    "description": "Parking pouvant accueillir un grand nombre de véhicules de tout genre, sauf les caravanes. ",
    "provider": "0b7956e6-1262-49f7-aaab-c5ab60d16cba",
    "service": "",
    "category": "parkingsBleu"
}, {
    "shape_id": 22,
    "type": "shape",
    "coordinates": [[{"lat": 47.9431193050635, "lng": 0.2063790679229616}, {
        "lat": 47.94163793270639,
        "lng": 0.20660431412321326
    }, {"lat": 47.94142219532671, "lng": 0.20560679523652153}, {
        "lat": 47.940429791783934,
        "lng": 0.2058320414367332
    }, {"lat": 47.94065991604118, "lng": 0.20759110700034447}, {
        "lat": 47.941098587569066,
        "lng": 0.20752675094314113
    }, {"lat": 47.942126934100465, "lng": 0.20746239488593782}, {"lat": 47.94325593435622, "lng": 0.2073873128191739}]],
    "name": "Parking Sud-Ouest",
    "logistics": "Places, Bornes de recharge",
    "surface": "400",
    "description": "Parking pouvant accueillir un grand nombre de véhicules de tout genre, sauf les caravanes. ",
    "provider": "0b7956e6-1262-49f7-aaab-c5ab60d16cba",
    "service": "",
    "category": "parkingsBleu"
}, {
    "shape_id": 23,
    "type": "shape",
    "coordinates": [[{"lat": 47.95621039274456, "lng": 0.21908106293148322}, {
        "lat": 47.95612412222934,
        "lng": 0.2180299139971487
    }, {"lat": 47.95608597264897, "lng": 0.21796094820419223}, {
        "lat": 47.955341934421696,
        "lng": 0.21904431897828316
    }, {"lat": 47.955927861041005, "lng": 0.22001502284113353}, {
        "lat": 47.95598896956961,
        "lng": 0.21908722301644537
    }]],
    "name": "Base de campement",
    "logistics": "Electricité, Eau, Tentes, Personnel",
    "surface": "250",
    "description": "Reposez-vous au sein de notre domaine.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "Billetterie",
    "category": "emplacements"
}, {
    "shape_id": 24,
    "type": "shape",
    "coordinates": [[{"lat": 47.95607272167667, "lng": 0.21792353577775542}, {
        "lat": 47.955116546203115,
        "lng": 0.21929646499810665
    }, {"lat": 47.95424303209053, "lng": 0.2180468848874018}, {
        "lat": 47.95381029390902,
        "lng": 0.2185557852494924
    }, {"lat": 47.95457237926881, "lng": 0.2198858104317214}, {
        "lat": 47.95319917960419,
        "lng": 0.2194031400026564
    }, {"lat": 47.95271923179654, "lng": 0.21864227734493816}, {
        "lat": 47.95484375574316,
        "lng": 0.21575672356641817
    }, {"lat": 47.9549336230869, "lng": 0.2158318056331821}]],
    "name": "Base de campement",
    "logistics": "Electricité, Eau, Tentes, Personnel",
    "surface": "550",
    "description": "Reposez-vous au sein de notre domaine.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "Billetterie",
    "category": "emplacements"
}, {
    "shape_id": 25,
    "type": "shape",
    "coordinates": [[{"lat": 47.95309323436196, "lng": 0.21783678081542138}, {
        "lat": 47.95274813061866,
        "lng": 0.21833017725400697
    }, {"lat": 47.95266904401955, "lng": 0.21816928711099862}, {
        "lat": 47.952503680739376,
        "lng": 0.2183623552826086
    }, {"lat": 47.95264028522622, "lng": 0.21858760148282033}, {
        "lat": 47.95245335267952,
        "lng": 0.21900591585464207
    }, {"lat": 47.95237426562926, "lng": 0.21902736787372315}, {
        "lat": 47.95225203994987,
        "lng": 0.2189415597974387
    }, {"lat": 47.95026936004262, "lng": 0.21556233258090973}, {
        "lat": 47.94917646389674,
        "lng": 0.21381399302681903
    }, {"lat": 47.948572485058705, "lng": 0.21288083019737059}, {
        "lat": 47.948191399541095,
        "lng": 0.21284865216876894
    }, {"lat": 47.9481842092213, "lng": 0.21188331131071883}, {
        "lat": 47.94977324557769,
        "lng": 0.21293446024505341
    }, {"lat": 47.95130449566493, "lng": 0.21372785424707}, {
        "lat": 47.951793405612456,
        "lng": 0.21415689462845228
    }, {"lat": 47.951426723585435, "lng": 0.21516513952466457}]],
    "name": "Base de campement",
    "logistics": "Electricité, Eau, Tentes, Personnel",
    "surface": "750",
    "description": "Reposez-vous au sein de notre domaine.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "Billetterie",
    "category": "emplacements"
}, {
    "shape_id": 26,
    "type": "shape",
    "coordinates": [[{"lat": 47.9481427524243, "lng": 0.21283669096757943}, {
        "lat": 47.94805646843954,
        "lng": 0.2117855420332449
    }, {"lat": 47.94790547111959, "lng": 0.21094891328960144}, {
        "lat": 47.94759628475461,
        "lng": 0.20980123026946187
    }, {"lat": 47.946819715495124, "lng": 0.2100479284887147}, {
        "lat": 47.9466399524294,
        "lng": 0.21032680473660917
    }, {"lat": 47.94688443004571, "lng": 0.2123969245767166}, {
        "lat": 47.946970715987156,
        "lng": 0.2124720066434405
    }, {"lat": 47.94711452556947, "lng": 0.21253636270064383}, {
        "lat": 47.947193620669154,
        "lng": 0.2126865268341316
    }, {"lat": 47.94727990609441, "lng": 0.21280451293897776}]],
    "name": "Base de campement",
    "logistics": "Electricité, Eau, Tentes, Personnel",
    "surface": "425",
    "description": "Reposez-vous au sein de notre domaine.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "Billetterie",
    "category": "emplacements"
}, {
    "shape_id": 27,
    "type": "shape",
    "coordinates": [[{"lat": 47.94142184918883, "lng": 0.21839395104697348}, {
        "lat": 47.94048697680013,
        "lng": 0.21541212039649874
    }, {"lat": 47.93992604525197, "lng": 0.2147471078053842}, {
        "lat": 47.938962379408714,
        "lng": 0.21501525804371815
    }, {"lat": 47.93977502417987, "lng": 0.21743933619844394}, {"lat": 47.94059484755393, "lng": 0.2192091277715358}]],
    "name": "Base de campement",
    "logistics": "Electricité, Eau, Tentes, Personnel",
    "surface": "385",
    "description": "Reposez-vous au sein de notre domaine.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "Billetterie",
    "category": "emplacements"
}, {
    "shape_id": 28,
    "type": "shape",
    "coordinates": [[{"lat": 47.95056474860335, "lng": 0.2089333534240723}, {
        "lat": 47.95055037686306,
        "lng": 0.20945906639099124
    }, {"lat": 47.950636607244796, "lng": 0.20955562591552737}, {
        "lat": 47.953058185040014,
        "lng": 0.20964682102203372
    }, {"lat": 47.95307614884295, "lng": 0.2089333534240723}, {
        "lat": 47.95168213917449,
        "lng": 0.20887970924377441
    }, {"lat": 47.95166776774496, "lng": 0.20923376083374026}, {
        "lat": 47.95151686749355,
        "lng": 0.20922839641571048
    }, {"lat": 47.951502496018044, "lng": 0.20904600620269775}, {
        "lat": 47.951480938797296,
        "lng": 0.20887434482574466
    }, {"lat": 47.95084140382476, "lng": 0.20885288715362552}]],
    "name": "Ecuries piste des 24H du Mans",
    "logistics": "F1, F2, F3, F4, bolides",
    "surface": "1200",
    "description": "Garages avec tous les plus grands sponsors, tous au même endroit, rien que pour vous, vos yeux et vos coeurs.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "",
    "category": "garages"
}, {
    "shape_id": 29,
    "type": "shape",
    "coordinates": [[{"lat": 47.95107781715147, "lng": 0.20982384681701663}, {
        "lat": 47.950883800246714,
        "lng": 0.20980775356292727
    }, {"lat": 47.95089098607098, "lng": 0.2100169658660889}, {"lat": 47.95107063135316, "lng": 0.21018862724304202}]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "garages"
}, {
    "shape_id": 30,
    "type": "shape",
    "coordinates": [[{"lat": 47.95146225590376, "lng": 0.21042466163635257}, {
        "lat": 47.95113889639666,
        "lng": 0.21038174629211429
    }, {"lat": 47.95111374612797, "lng": 0.21029591560363772}, {
        "lat": 47.95112811771159,
        "lng": 0.20974338054656985
    }, {"lat": 47.95152333469456, "lng": 0.20977556705474856}]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "garages"
}, {
    "shape_id": 31,
    "type": "shape",
    "coordinates": [[{"lat": 47.95123231157322, "lng": 0.2104514837265015}, {
        "lat": 47.95148740600284,
        "lng": 0.2104836702346802
    }, {"lat": 47.95150177748255, "lng": 0.21031737327575686}, {
        "lat": 47.9516634563537,
        "lng": 0.21036565303802493
    }, {"lat": 47.9519293271753, "lng": 0.21069288253784182}, {
        "lat": 47.95208022622229,
        "lng": 0.21119177341461184
    }, {"lat": 47.951997591084464, "lng": 0.2113473415374756}, {
        "lat": 47.951932920014855,
        "lng": 0.21130442619323733
    }, {"lat": 47.951864656020255, "lng": 0.21125614643096927}]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "garages"
}, {
    "shape_id": 32,
    "type": "shape",
    "coordinates": [[{"lat": 47.95164926451806, "lng": 0.20981311798095706}, {
        "lat": 47.95192232109355,
        "lng": 0.2097916603088379
    }, {"lat": 47.95205166317834, "lng": 0.20989894866943362}, {
        "lat": 47.95274202108608,
        "lng": 0.2099740505218506
    }, {"lat": 47.95270968602722, "lng": 0.21090209484100345}, {
        "lat": 47.95242226239243,
        "lng": 0.21086454391479495
    }, {"lat": 47.952285735605884, "lng": 0.21078407764434817}, {
        "lat": 47.952174358223395,
        "lng": 0.2106285095214844
    }, {"lat": 47.951710882021565, "lng": 0.2099740505218506}]],
    "name": "Garage Porsche",
    "logistics": "Voitures, Prototypes, F1, F2, F3, F4",
    "surface": "250",
    "description": "Garage d'un sponsor.",
    "provider": "45309281-fc24-4e02-ad47-a275c64f5327",
    "service": "Ecurie",
    "category": "garages"
}, {
    "shape_id": 33,
    "type": "shape",
    "coordinates": [[{"lat": 47.95387157978228, "lng": 0.21128833293914795}, {
        "lat": 47.952366214602876,
        "lng": 0.2110952138900757
    }, {"lat": 47.952190166646155, "lng": 0.21144390106201175}, {
        "lat": 47.953828467308355,
        "lng": 0.21162629127502444
    }]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "garages"
}, {
    "shape_id": 34,
    "type": "shape",
    "coordinates": [[{"lat": 47.95396858271702, "lng": 0.21027982234954837}, {
        "lat": 47.95359494078297,
        "lng": 0.2102369070053101
    }, {"lat": 47.9535626062578, "lng": 0.21101474761962893}, {"lat": 47.953936248425656, "lng": 0.21104156970977786}]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "garages"
}, {
    "shape_id": 35,
    "type": "shape",
    "coordinates": [[{"lat": 47.95354536115846, "lng": 0.21024227142333987}, {
        "lat": 47.95315734514887,
        "lng": 0.2101939916610718
    }, {"lat": 47.953132195862516, "lng": 0.21096646785736087}, {
        "lat": 47.95351661933176,
        "lng": 0.21098256111145022
    }]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "garages"
}, {
    "shape_id": 36,
    "type": "shape",
    "coordinates": [[{"lat": 47.95302728728984, "lng": 0.21018862724304202}, {
        "lat": 47.95278297880375,
        "lng": 0.2101564407348633
    }, {"lat": 47.952761422117185, "lng": 0.2109235525131226}, {"lat": 47.95299495240947, "lng": 0.21092891693115237}]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "garages"
}, {
    "shape_id": 37,
    "type": "shape",
    "coordinates": [[{"lat": 47.94910024772768, "lng": 0.20978093147277835}, {
        "lat": 47.94905713127305,
        "lng": 0.2096521854400635
    }, {"lat": 47.94897089825589, "lng": 0.20959854125976565}, {
        "lat": 47.94881999012958,
        "lng": 0.20951807498931888
    }, {"lat": 47.948543324086955, "lng": 0.20945370197296145}, {
        "lat": 47.94849302101099,
        "lng": 0.20938932895660403
    }, {"lat": 47.94847505561484, "lng": 0.209464430809021}, {
        "lat": 47.9483636700194,
        "lng": 0.20931422710418704
    }, {"lat": 47.9483528907555, "lng": 0.20923912525177005}, {
        "lat": 47.94819838772572,
        "lng": 0.20923912525177005
    }, {"lat": 47.9481947946265, "lng": 0.20910501480102542}, {
        "lat": 47.94826306346881,
        "lng": 0.20901918411254886
    }, {"lat": 47.94815167741648, "lng": 0.20894944667816165}, {
        "lat": 47.948061849780075,
        "lng": 0.20890653133392337
    }, {"lat": 47.947950463294106, "lng": 0.2088636159896851}, {
        "lat": 47.947846262815695,
        "lng": 0.2088636159896851
    }, {"lat": 47.947551625843424, "lng": 0.20894944667816165}, {
        "lat": 47.94763067495209,
        "lng": 0.2095770835876465
    }, {"lat": 47.94769175827138, "lng": 0.20960927009582522}, {
        "lat": 47.947907345880246,
        "lng": 0.20957171916961673
    }, {"lat": 47.94809418774717, "lng": 0.20956099033355716}, {
        "lat": 47.94843553172136,
        "lng": 0.2096575498580933
    }, {"lat": 47.948510986400876, "lng": 0.2096199989318848}, {
        "lat": 47.948794838732354,
        "lng": 0.20964145660400393
    }]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "garages"
}, {
    "shape_id": 38,
    "type": "shape",
    "coordinates": [[{"lat": 47.947537971883264, "lng": 0.20944297313690188}, {
        "lat": 47.94747688838217,
        "lng": 0.2089333534240723
    }, {"lat": 47.94709601315873, "lng": 0.209040641784668}, {
        "lat": 47.94686245620892,
        "lng": 0.20915865898132327
    }, {"lat": 47.946661237067396, "lng": 0.20931422710418704}, {
        "lat": 47.94672232153246,
        "lng": 0.20977556705474856
    }]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "garages"
}, {
    "shape_id": 39,
    "type": "shape",
    "coordinates": [[{"lat": 47.946443667978215, "lng": 0.20985603332519534}, {
        "lat": 47.94631431185761,
        "lng": 0.20934104919433594
    }, {"lat": 47.94577730084517, "lng": 0.20953953266143802}, {"lat": 47.94588150549476, "lng": 0.2100813388824463}]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "garages"
}, {
    "shape_id": 40,
    "type": "shape",
    "coordinates": [[{"lat": 47.945856352667555, "lng": 0.21010816097259524}, {
        "lat": 47.945752147967255,
        "lng": 0.2095502614974976
    }, {"lat": 47.94557248419751, "lng": 0.2096199989318848}, {
        "lat": 47.94552936479985,
        "lng": 0.20967900753021243
    }, {"lat": 47.944742429473955, "lng": 0.20999550819396975}, {
        "lat": 47.94464181587441,
        "lng": 0.21002769470214847
    }, {"lat": 47.94446574160395, "lng": 0.2101939916610718}, {
        "lat": 47.94454838878519,
        "lng": 0.21060168743133548
    }, {"lat": 47.944742429473955, "lng": 0.21047294139862063}, {
        "lat": 47.944746022813185,
        "lng": 0.21053731441497803
    }]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "garages"
}, {
    "shape_id": 41,
    "type": "shape",
    "coordinates": [[{"lat": 47.94554014465264, "lng": 0.211063027381897}, {
        "lat": 47.94543953260587,
        "lng": 0.21052658557891848
    }, {"lat": 47.94548265207846, "lng": 0.21039247512817386}, {
        "lat": 47.94476758284323,
        "lng": 0.21066606044769287
    }, {"lat": 47.9447711761807, "lng": 0.21136343479156497}]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "garages"
}, {
    "shape_id": 42,
    "type": "shape",
    "coordinates": [[{"lat": 47.945622790116, "lng": 0.21137952804565432}, {
        "lat": 47.94547187221369,
        "lng": 0.21053731441497803
    }, {"lat": 47.94551499165931, "lng": 0.21038174629211429}, {
        "lat": 47.94614381282063,
        "lng": 0.21012961864471436
    }, {"lat": 47.94626957613489, "lng": 0.21119177341461184}]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "garages"
}, {
    "shape_id": 43,
    "type": "shape",
    "coordinates": [[{"lat": 47.94654625434909, "lng": 0.21036028861999514}, {
        "lat": 47.94643127137498,
        "lng": 0.2101993560791016
    }, {"lat": 47.94618333846665, "lng": 0.2102905511856079}, {
        "lat": 47.9463198813749,
        "lng": 0.21128296852111816
    }, {"lat": 47.94663249141179, "lng": 0.21119713783264163}]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "garages"
}, {
    "shape_id": 44,
    "type": "shape",
    "coordinates": [[{"lat": 47.94677262633183, "lng": 0.21237194538116458}, {
        "lat": 47.94664327103451,
        "lng": 0.21123468875885013
    }, {"lat": 47.94632347460447, "lng": 0.21134197711944583}, {
        "lat": 47.946406118815304,
        "lng": 0.21207153797149658
    }, {"lat": 47.94648516967594, "lng": 0.21205008029937747}, {
        "lat": 47.9465498475629,
        "lng": 0.2125060558319092
    }, {"lat": 47.94671872833063, "lng": 0.2124738693237305}]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "garages"
}, {
    "shape_id": 45,
    "type": "shape",
    "coordinates": [[{"lat": 47.94638096624338, "lng": 0.21206617355346682}, {
        "lat": 47.94627676260073,
        "lng": 0.21121323108673098
    }, {"lat": 47.94560841700141, "lng": 0.2114224433898926}, {
        "lat": 47.94555092450319,
        "lng": 0.2110952138900757
    }, {"lat": 47.9447711761807, "lng": 0.21140635013580325}, {
        "lat": 47.94477836285488,
        "lng": 0.21277427673339847
    }, {"lat": 47.94501552254265, "lng": 0.21292448043823245}, {
        "lat": 47.94573418161837,
        "lng": 0.2127635478973389
    }, {"lat": 47.945859945929335, "lng": 0.2124685049057007}, {
        "lat": 47.94592821785568,
        "lng": 0.21234512329101565
    }, {"lat": 47.94600008294393, "lng": 0.21230757236480716}, {
        "lat": 47.94635940688627,
        "lng": 0.21220028400421143
    }, {"lat": 47.94633066106281, "lng": 0.21206080913543704}]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "garages"
}, {
    "shape_id": 46,
    "type": "shape",
    "coordinates": [[{"lat": 47.94461306909571, "lng": 0.2107411623001099}, {
        "lat": 47.94389080103397,
        "lng": 0.2108055353164673
    }, {"lat": 47.94391236142074, "lng": 0.2114653587341309}, {
        "lat": 47.944271699876516,
        "lng": 0.21140635013580325
    }, {"lat": 47.94429685347486, "lng": 0.21220564842224124}, {
        "lat": 47.94394110858913,
        "lng": 0.21224319934844973
    }, {"lat": 47.943977042527145, "lng": 0.2129781246185303}, {"lat": 47.94465259591228, "lng": 0.2129083871841431}]],
    "name": "Garage Kart'24",
    "logistics": "Voitures, Prototypes, Karts",
    "surface": "300",
    "description": "Garage d'un sponsor.",
    "provider": "524aaa51-09c1-48f1-85d3-ac878394e1ff",
    "service": "",
    "category": "garages"
}, {
    "shape_id": 47,
    "type": "shape",
    "coordinates": [[{"lat": 47.95128997735212, "lng": 0.21759152412414554}, {
        "lat": 47.95048516503586,
        "lng": 0.21813869476318362
    }, {"lat": 47.94922043464411, "lng": 0.21584272384643555}, {
        "lat": 47.94908389939856,
        "lng": 0.21597146987915042
    }, {"lat": 47.949055155090385, "lng": 0.2162289619445801}, {
        "lat": 47.949184504351315,
        "lng": 0.21657228469848636
    }, {"lat": 47.94833654218998, "lng": 0.21773099899291995}, {
        "lat": 47.94947913201492,
        "lng": 0.2217650413513184
    }, {"lat": 47.94999652287169, "lng": 0.22152900695800784}, {
        "lat": 47.94991747738159,
        "lng": 0.22098183631896973
    }, {"lat": 47.95160615019005, "lng": 0.22032737731933597}, {
        "lat": 47.95151273568926,
        "lng": 0.22015571594238284
    }, {"lat": 47.9515774072847, "lng": 0.22000551223754886}, {
        "lat": 47.95057858139411,
        "lng": 0.21831035614013675
    }, {"lat": 47.951376206499766, "lng": 0.21772027015686038}]],
    "name": "Base de campement",
    "logistics": "Electricité, Eau, Tentes, Personnel",
    "surface": "800",
    "description": "Reposez-vous au sein de notre domaine.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "Billetterie",
    "category": "emplacements"
}, {
    "shape_id": 48,
    "type": "shape",
    "coordinates": [[{"lat": 47.955029315888986, "lng": 0.21040320396423343}, {
        "lat": 47.954935907576285,
        "lng": 0.21032810211181643
    }, {"lat": 47.95459101388201, "lng": 0.21029591560363772}, {
        "lat": 47.954447307496636,
        "lng": 0.21039247512817386
    }, {"lat": 47.9543395274453, "lng": 0.21041393280029297}, {
        "lat": 47.95426767395287,
        "lng": 0.2105212211608887
    }, {"lat": 47.95420300572423, "lng": 0.2107787132263184}, {
        "lat": 47.954188634995774,
        "lng": 0.21097183227539065
    }, {"lat": 47.95451916073928, "lng": 0.21155118942260745}, {
        "lat": 47.95463412571969,
        "lng": 0.21173357963562014
    }, {"lat": 47.9548281285441, "lng": 0.21180868148803714}, {"lat": 47.954986204381036, "lng": 0.211787223815918}]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "garages"
}, {
    "shape_id": 49,
    "type": "shape",
    "coordinates": [[{"lat": 47.955898723622305, "lng": 0.2111220359802246}, {
        "lat": 47.95558257704822,
        "lng": 0.2113902568817139
    }, {"lat": 47.95546761417807, "lng": 0.21140098571777347}, {
        "lat": 47.955079612602795,
        "lng": 0.21180868148803714
    }, {"lat": 47.95518020588356, "lng": 0.21039247512817386}, {
        "lat": 47.95555383635465,
        "lng": 0.2105855941772461
    }, {"lat": 47.95575502087391, "lng": 0.2108430862426758}]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "garages"
}, {
    "shape_id": 50,
    "type": "shape",
    "coordinates": [[{"lat": 47.95506380509138, "lng": 0.21420121192932132}, {
        "lat": 47.95306626877952,
        "lng": 0.21184086799621585
    }, {"lat": 47.95383511383902, "lng": 0.21190524101257324}, {"lat": 47.95466861518587, "lng": 0.21321415901184085}]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "garages"
}, {
    "shape_id": 51,
    "type": "shape",
    "coordinates": [[{"lat": 47.95908451091617, "lng": 0.21205544471740725}, {
        "lat": 47.95883304635013,
        "lng": 0.21259188652038577
    }, {"lat": 47.95852410249336, "lng": 0.21293520927429202}, {
        "lat": 47.95816486312759,
        "lng": 0.21198034286499026
    }, {"lat": 47.95796368799169, "lng": 0.21207690238952637}, {
        "lat": 47.9578774698365,
        "lng": 0.21143317222595218
    }, {"lat": 47.95769066334002, "lng": 0.21090745925903323}, {"lat": 47.95816486312759, "lng": 0.21048903465271}]],
    "name": "Parking Nord-Ouest",
    "logistics": "Places, Bornes de recharge",
    "surface": "450",
    "description": "Parking pouvant accueillir un grand nombre de véhicules de tout genre, sauf les caravanes. ",
    "provider": "0b7956e6-1262-49f7-aaab-c5ab60d16cba",
    "service": "",
    "category": "parkingsVip"
}, {
    "shape_id": 52,
    "type": "shape",
    "coordinates": [[{"lat": 47.958511169897605, "lng": 0.21461963653564456}, {
        "lat": 47.95824533294069,
        "lng": 0.21419048309326175
    }, {"lat": 47.95789327648713, "lng": 0.21309614181518557}, {
        "lat": 47.956923312623886,
        "lng": 0.21204471588134768
    }, {"lat": 47.9567580577058, "lng": 0.21213054656982425}, {
        "lat": 47.9558958495603,
        "lng": 0.21041393280029297
    }, {"lat": 47.95573059135593, "lng": 0.20998477935791018}, {
        "lat": 47.95543599933296,
        "lng": 0.2092123031616211
    }, {"lat": 47.95505518276552, "lng": 0.2101564407348633}, {
        "lat": 47.95551503638203,
        "lng": 0.21041393280029297
    }, {"lat": 47.95578088738715, "lng": 0.21072506904602054}, {
        "lat": 47.955910219813966,
        "lng": 0.21105766296386722
    }, {"lat": 47.95657843219532, "lng": 0.21237730979919436}, {
        "lat": 47.95652813694047,
        "lng": 0.21249532699584964
    }, {"lat": 47.95726100581291, "lng": 0.2139222621917725}, {
        "lat": 47.957728024671425,
        "lng": 0.21461963653564456
    }, {"lat": 47.95818785449988, "lng": 0.21502733230590823}, {
        "lat": 47.958331550482015,
        "lng": 0.21504878997802737
    }]],
    "name": "Parking Central",
    "logistics": "Places, Bornes de recharge",
    "surface": "850",
    "description": "Parking pouvant accueillir un grand nombre de véhicules de tout genre, sauf les caravanes. ",
    "provider": "0b7956e6-1262-49f7-aaab-c5ab60d16cba",
    "service": "",
    "category": "parkingsVip"
}, {
    "shape_id": 53,
    "type": "shape",
    "coordinates": [[{"lat": 47.954277553813995, "lng": 0.20942687988281253}, {
        "lat": 47.95428473916637,
        "lng": 0.2097219228744507
    }, {"lat": 47.954223663639404, "lng": 0.2097272872924805}, {
        "lat": 47.95421647827853,
        "lng": 0.20964145660400393
    }, {"lat": 47.954101512368915, "lng": 0.20962536334991458}, {
        "lat": 47.954101512368915,
        "lng": 0.2093625068664551
    }, {"lat": 47.95405121470299, "lng": 0.20937323570251468}, {
        "lat": 47.95405840008684,
        "lng": 0.20924985408782962
    }, {"lat": 47.9542200709591, "lng": 0.20924448966979983}, {"lat": 47.95427396113744, "lng": 0.20929813385009766}]],
    "name": "Boutique Kart'24",
    "logistics": "Aire de jeu, Boutique, Fast Food",
    "surface": "125",
    "description": "Retrouvez tous ce qu'il vous faut au sein du village des 24H du Mans, boutique, fast food, aire de repos et de jeux.",
    "provider": "524aaa51-09c1-48f1-85d3-ac878394e1ff",
    "service": "",
    "category": "boutique"
}, {
    "shape_id": 54,
    "type": "shape",
    "coordinates": [[{"lat": 47.954004509683685, "lng": 0.20923376083374026}, {
        "lat": 47.95400810237899,
        "lng": 0.20971655845642093
    }, {"lat": 47.95394702652504, "lng": 0.20970582962036136}, {
        "lat": 47.95395780462216,
        "lng": 0.20963072776794434
    }, {"lat": 47.95383565272317, "lng": 0.2096199989318848}, {
        "lat": 47.95383924543021,
        "lng": 0.20935714244842532
    }, {"lat": 47.953785354798484, "lng": 0.2093678712844849}, {
        "lat": 47.953785354798484,
        "lng": 0.20923376083374026
    }]],
    "name": "Boutique 24H du Mans",
    "logistics": "Aire de jeu, Boutique, Fast Food",
    "surface": "125",
    "description": "Retrouvez tous ce qu'il vous faut au sein du village des 24H du Mans, boutique, fast food, aire de repos et de jeux.",
    "provider": "255da203-781d-4e50-924f-0423638cdb68",
    "service": "",
    "category": "boutique"
}, {
    "shape_id": 55,
    "type": "shape",
    "coordinates": [[{"lat": 47.953727871396026, "lng": 0.20969510078430179}, {
        "lat": 47.95373864953887,
        "lng": 0.2092230319976807
    }, {"lat": 47.953508715337556, "lng": 0.2092123031616211}, {
        "lat": 47.953508715337556,
        "lng": 0.20934104919433594
    }, {"lat": 47.95357697716035, "lng": 0.20935177803039554}, {
        "lat": 47.953555420805024,
        "lng": 0.20960927009582522
    }, {"lat": 47.953695536954065, "lng": 0.20959317684173587}, {
        "lat": 47.953677573366434,
        "lng": 0.20966827869415283
    }]],
    "name": "Boutique Porsche",
    "logistics": "Aire de jeu, Boutique, Fast Food",
    "surface": "125",
    "description": "Retrouvez tous ce qu'il vous faut au sein du village des 24H du Mans, boutique, fast food, aire de repos et de jeux.",
    "provider": "45309281-fc24-4e02-ad47-a275c64f5327",
    "service": "Boutique",
    "category": "boutique"
}, {
    "shape_id": 56,
    "type": "shape",
    "coordinates": [[{"lat": 47.953454105811645, "lng": 0.20996332168579102}, {
        "lat": 47.95345051307784,
        "lng": 0.21012425422668457
    }, {"lat": 47.9533247672371, "lng": 0.2101564407348633}, {
        "lat": 47.95332835997966,
        "lng": 0.21007061004638675
    }, {"lat": 47.953245726837736, "lng": 0.21008670330047607}, {
        "lat": 47.953245726837736,
        "lng": 0.20993113517761233
    }, {"lat": 47.95336069465141, "lng": 0.2099472284317017}, {
        "lat": 47.95336428739145,
        "lng": 0.20986139774322513
    }, {"lat": 47.9534361421401, "lng": 0.20983994007110596}]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "boutique"
}, {
    "shape_id": 57,
    "type": "shape",
    "coordinates": [[{"lat": 47.95339446640354, "lng": 0.20814478397369388}, {
        "lat": 47.953398059141236,
        "lng": 0.2085739374160767
    }, {"lat": 47.95335494627229, "lng": 0.2085685729980469}, {
        "lat": 47.95335853901274,
        "lng": 0.20870804786682132
    }, {"lat": 47.953232792948185, "lng": 0.20874023437500003}, {
        "lat": 47.95321123644925,
        "lng": 0.20857930183410647
    }, {"lat": 47.9531896799413, "lng": 0.20850419998168948}, {"lat": 47.95321123644925, "lng": 0.2080589532852173}]],
    "name": "Boutique 24H du Mans",
    "logistics": "Aire de jeu, Boutique, Fast Food",
    "surface": "125",
    "description": "Retrouvez tous ce qu'il vous faut au sein du village des 24H du Mans, boutique, fast food, aire de repos et de jeux.",
    "provider": "af3a0f62-5b13-4b19-9d42-736870b268a0",
    "service": "",
    "category": "boutique"
}, {
    "shape_id": 58,
    "type": "shape",
    "coordinates": [[{"lat": 47.952029207992226, "lng": 0.21165311336517337}, {
        "lat": 47.95152261609083,
        "lng": 0.2110254764556885
    }, {"lat": 47.951393272681884, "lng": 0.21133661270141604}]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "garages"
}, {
    "shape_id": 59,
    "type": "shape",
    "coordinates": [[{"lat": 47.95150177745512, "lng": 0.21100401878356936}, {
        "lat": 47.95084068525263,
        "lng": 0.21012961864471436
    }, {"lat": 47.950761641053695, "lng": 0.21011352539062503}, {
        "lat": 47.9506466674576,
        "lng": 0.21025300025939941
    }, {"lat": 47.95058918056361, "lng": 0.210338830947876}, {
        "lat": 47.950492171285035,
        "lng": 0.21038174629211429
    }, {"lat": 47.95040594066229, "lng": 0.21043002605438235}, {
        "lat": 47.95033408170012,
        "lng": 0.21047294139862063
    }, {"lat": 47.9502873733211, "lng": 0.21074652671813965}, {"lat": 47.95136884111548, "lng": 0.21132588386535645}]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "garages"
}, {
    "shape_id": 60,
    "type": "shape",
    "coordinates": [[{"lat": 47.95428976891793, "lng": 0.20876705646514895}, {
        "lat": 47.95428617624219,
        "lng": 0.2086812257766724
    }, {"lat": 47.95422869339689, "lng": 0.20867049694061282}, {
        "lat": 47.95423587875606,
        "lng": 0.20859003067016602
    }, {"lat": 47.95407061524256, "lng": 0.20859003067016602}, {
        "lat": 47.954067022551605,
        "lng": 0.20877242088317874
    }]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "boutique"
}, {
    "shape_id": 61,
    "type": "shape",
    "coordinates": [[{"lat": 47.95402031754659, "lng": 0.20875632762908936}, {
        "lat": 47.95401313215745,
        "lng": 0.20855247974395752
    }, {"lat": 47.95379397730881, "lng": 0.20855247974395752}, {"lat": 47.95379397730881, "lng": 0.20876169204711914}]],
    "name": "",
    "logistics": "",
    "surface": "",
    "description": "",
    "provider": null,
    "service": "",
    "category": "boutique"
}, {
    "shape_id": 62,
    "type": "shape",
    "coordinates": [[{"lat": 47.953746553497886, "lng": 0.2087509632110596}, {
        "lat": 47.953746553497886,
        "lng": 0.20850419998168948
    }, {"lat": 47.953484284757586, "lng": 0.20850956439971924}, {
        "lat": 47.95348069202586,
        "lng": 0.20857930183410647
    }, {"lat": 47.95353099024711, "lng": 0.2085953950881958}, {"lat": 47.95352739751865, "lng": 0.20874023437500003}]],
    "name": "Boutique Codeky",
    "logistics": "Aire de jeu, Boutique, Fast Food",
    "surface": "125",
    "description": "Retrouvez tous ce qu'il vous faut au sein du village des 24H du Mans, boutique, fast food, aire de repos et de jeux.",
    "provider": "0b7956e6-1262-49f7-aaab-c5ab60d16cba",
    "service": "",
    "category": "boutique"
}, {
    "shape_id": 63,
    "type": "shape",
    "coordinates": [[{"lat": 47.95570616185386, "lng": 0.2225589752197266}, {
        "lat": 47.95552653268646,
        "lng": 0.2224516868591309
    }, {"lat": 47.955368458502576, "lng": 0.22234439849853518}, {
        "lat": 47.95518882816115,
        "lng": 0.22217273712158206
    }, {"lat": 47.95503793819174, "lng": 0.2219796180725098}, {
        "lat": 47.95492297410977,
        "lng": 0.22174358367919925
    }, {"lat": 47.95477926864762, "lng": 0.22134661674499514}, {
        "lat": 47.95475052750723,
        "lng": 0.22113204002380374
    }, {"lat": 47.95470741576667, "lng": 0.22086381912231448}, {
        "lat": 47.9546858598829,
        "lng": 0.22054195404052737
    }, {"lat": 47.9547002304731, "lng": 0.22032737731933597}, {
        "lat": 47.953888285861716,
        "lng": 0.2199196815490723
    }, {"lat": 47.95383798798827, "lng": 0.22037029266357425}, {
        "lat": 47.95383798798827,
        "lng": 0.22077798843383792
    }, {"lat": 47.95385235881424, "lng": 0.22118568420410156}, {
        "lat": 47.953888285861716,
        "lng": 0.22162556648254397
    }, {"lat": 47.953960139881715, "lng": 0.22202253341674807}, {
        "lat": 47.95405354995835,
        "lng": 0.22229075431823733
    }, {"lat": 47.95415414523665, "lng": 0.22253751754760745}, {
        "lat": 47.95426911102918,
        "lng": 0.22274136543273929
    }, {"lat": 47.95440563257559, "lng": 0.22294521331787112}, {
        "lat": 47.95452778312715,
        "lng": 0.22310614585876468
    }, {"lat": 47.95464993338998, "lng": 0.22329926490783691}, {
        "lat": 47.95475771279383,
        "lng": 0.22343873977661136
    }, {"lat": 47.95489423304932, "lng": 0.2235674858093262}, {
        "lat": 47.955023567695484,
        "lng": 0.2236425876617432
    }, {"lat": 47.95516008724856, "lng": 0.22371768951416018}, {"lat": 47.95533971768991, "lng": 0.2238893508911133}]],
    "name": "Parking Nord-Est",
    "logistics": "Places, Bornes de recharge",
    "surface": "650",
    "description": "Parking pouvant accueillir un grand nombre de véhicules de tout genre, sauf les caravanes. ",
    "provider": "0b7956e6-1262-49f7-aaab-c5ab60d16cba",
    "service": "",
    "category": "parkingsRouge"
}, {
    "shape_id": 64,
    "type": "shape",
    "coordinates": [[{"lat": 47.953786073343366, "lng": 0.22134661674499514}, {
        "lat": 47.953764517075236,
        "lng": 0.22100865840911868
    }, {"lat": 47.953771702498926, "lng": 0.22082626819610598}, {
        "lat": 47.953764517075236,
        "lng": 0.22054731845855716
    }, {"lat": 47.953768109787205, "lng": 0.22032737731933597}, {
        "lat": 47.953786073343366,
        "lng": 0.22012889385223391
    }, {"lat": 47.953796851474046, "lng": 0.2199035882949829}, {
        "lat": 47.95304596965868,
        "lng": 0.21957635879516604
    }, {"lat": 47.95296692883284, "lng": 0.22016108036041263}, {
        "lat": 47.953581288433114,
        "lng": 0.22138953208923343
    }]],
    "name": "Parking Nord-Est",
    "logistics": "Places, Bornes de recharge",
    "surface": "350",
    "description": "Parking pouvant accueillir un grand nombre de véhicules de tout genre, sauf les caravanes. ",
    "provider": "0b7956e6-1262-49f7-aaab-c5ab60d16cba",
    "service": "",
    "category": "parkingsRouge"
}, {
    "shape_id": 65,
    "type": "shape",
    "coordinates": [[{"lat": 47.948017474875556, "lng": 0.21892189979553225}, {
        "lat": 47.94923911840296,
        "lng": 0.22373914718627932
    }, {"lat": 47.94926067655886, "lng": 0.2240395545959473}, {
        "lat": 47.949267862608814,
        "lng": 0.22431850433349612
    }, {"lat": 47.94924630445592, "lng": 0.2247905731201172}, {
        "lat": 47.949152885689415,
        "lng": 0.22498369216918948
    }, {"lat": 47.94902353634935, "lng": 0.22506952285766604}, {
        "lat": 47.94890137278648,
        "lng": 0.22520899772644046
    }, {"lat": 47.9488151395094, "lng": 0.22532701492309573}, {
        "lat": 47.94862830024883,
        "lng": 0.22546648979187014
    }, {"lat": 47.948405529478435, "lng": 0.22548794746398929}, {
        "lat": 47.94823306112576,
        "lng": 0.22540211677551272
    }, {"lat": 47.94804621976089, "lng": 0.22531628608703616}, {
        "lat": 47.94790249517442,
        "lng": 0.22512316703796387
    }, {"lat": 47.94772283887931, "lng": 0.2247262001037598}, {
        "lat": 47.94652271880885,
        "lng": 0.21990895271301272
    }, {"lat": 47.94647960020389, "lng": 0.2195549011230469}, {
        "lat": 47.94653709166917,
        "lng": 0.21913647651672366
    }, {"lat": 47.946637701579505, "lng": 0.21885752677917483}, {
        "lat": 47.94678142968313,
        "lng": 0.21860003471374514
    }, {"lat": 47.94694671650819, "lng": 0.21844983100891116}, {
        "lat": 47.94707607104607,
        "lng": 0.21832108497619632
    }, {"lat": 47.94738508335385, "lng": 0.2182459831237793}, {
        "lat": 47.94755755453625,
        "lng": 0.21829962730407718
    }, {"lat": 47.94770846634872, "lng": 0.21844983100891116}, {"lat": 47.94785219147474, "lng": 0.21862149238586426}]],
    "name": "Site Est de départ de Montgolfière",
    "logistics": "Gaz, Montgolfière, Personnel",
    "surface": "200",
    "description": "Site de départ des montgolfières Est, grand tour au dessus des 24H Du Mans.",
    "provider": "255da203-781d-4e50-924f-0423638cdb68",
    "service": "",
    "category": "montgolfieres"
}, {
    "shape_id": 66,
    "type": "shape",
    "coordinates": [[{"lat": 47.952691542419174, "lng": 0.20472764968872073}, {
        "lat": 47.944614326734715,
        "lng": 0.20017862319946292
    }, {"lat": 47.9444705926049, "lng": 0.20071506500244143}, {"lat": 47.95264842896073, "lng": 0.2051997184753418}]],
    "name": "Site Ouest de départ de Montgolfière",
    "logistics": "Gaz, Montgolfière, Personnel",
    "surface": "150",
    "description": "Site de départ des montgolfières Ouest, grand tour au dessus des 24H Du Mans.",
    "provider": "255da203-781d-4e50-924f-0423638cdb68",
    "service": "",
    "category": "montgolfieres"
}, {
    "shape_id": 67,
    "type": "shape",
    "coordinates": [[{"lat": 47.9436154385842, "lng": 0.2109992504301772}, {
        "lat": 47.94192705163992,
        "lng": 0.2111870050612197
    }, {"lat": 47.9418444270337, "lng": 0.2112942934218154}, {
        "lat": 47.94176180229541,
        "lng": 0.21139085294635154
    }, {"lat": 47.94170791652538, "lng": 0.2115195989790664}, {
        "lat": 47.941503150087,
        "lng": 0.211728811282228
    }, {"lat": 47.94143489442728, "lng": 0.2118629217329726}, {
        "lat": 47.94142770961576,
        "lng": 0.2120560407820449
    }, {"lat": 47.94147081846993, "lng": 0.2122062444868789}, {
        "lat": 47.94154985127591,
        "lng": 0.21228134633929585
    }, {"lat": 47.94166121547924, "lng": 0.21228134633929585}, {
        "lat": 47.94172228606953,
        "lng": 0.21222233774096824
    }, {"lat": 47.941786948968875, "lng": 0.2121150493803725}, {
        "lat": 47.941840834656546,
        "lng": 0.21201312543780662
    }, {"lat": 47.941916274524885, "lng": 0.21190047265918113}, {
        "lat": 47.94200608374842,
        "lng": 0.2118682861510024
    }, {"lat": 47.942156962892724, "lng": 0.2118360996428237}, {
        "lat": 47.942979605718946,
        "lng": 0.211653709429811
    }, {"lat": 47.94306582080308, "lng": 0.21168053151995994}, {
        "lat": 47.943098151422554,
        "lng": 0.2117663622084365
    }, {"lat": 47.943148443457105, "lng": 0.21261394025714253}, {
        "lat": 47.94315203574342,
        "lng": 0.2127051353636489
    }, {"lat": 47.943116112869085, "lng": 0.21278560163409568}, {
        "lat": 47.94306582080308,
        "lng": 0.21286070348651265
    }, {"lat": 47.94297601342065, "lng": 0.21287679674060203}, {
        "lat": 47.94285028282311,
        "lng": 0.21287143232257225
    }, {"lat": 47.942749698124906, "lng": 0.21279096605212547}, {
        "lat": 47.94268503642984,
        "lng": 0.2126944065275893
    }, {"lat": 47.94267785179208, "lng": 0.2125978470030532}, {
        "lat": 47.94273532886635,
        "lng": 0.212453007716249
    }, {"lat": 47.942832321284214, "lng": 0.2123135328474746}, {
        "lat": 47.94283591359251,
        "lng": 0.21220088006884907
    }, {"lat": 47.94279639818772, "lng": 0.21204531194598533}, {
        "lat": 47.942724551919774,
        "lng": 0.21199166776568745
    }, {"lat": 47.942358134400095, "lng": 0.2121150493803725}, {
        "lat": 47.94230424925167,
        "lng": 0.2121686935606704
    }, {"lat": 47.94226832578816, "lng": 0.21225988866717674}, {
        "lat": 47.94226114109249,
        "lng": 0.21235644819171287
    }, {"lat": 47.94224677169808, "lng": 0.21243155004412986}, {
        "lat": 47.942203663490986,
        "lng": 0.21250665189654686
    }, {"lat": 47.94212822404202, "lng": 0.21253883840472557}, {
        "lat": 47.94168995458955,
        "lng": 0.21258175374896385
    }, {"lat": 47.941636068744636, "lng": 0.2125978470030532}, {
        "lat": 47.94158218284354,
        "lng": 0.2126353979292617
    }, {"lat": 47.94112235420278, "lng": 0.2133166790190444}, {
        "lat": 47.94111876177543,
        "lng": 0.21341860296161033
    }, {"lat": 47.94114031633583, "lng": 0.21351516248614646}, {
        "lat": 47.94120497996312,
        "lng": 0.2135741710844741
    }, {"lat": 47.94128760559137, "lng": 0.213600993174623}, {
        "lat": 47.941366638677444,
        "lng": 0.2135795355025039
    }, {"lat": 47.94142770961576, "lng": 0.21352052690417622}, {
        "lat": 47.941503150087,
        "lng": 0.2133917808714614
    }, {"lat": 47.941636068744636, "lng": 0.21312355996997215}, {
        "lat": 47.94172228606953,
        "lng": 0.21308600904376365
    }, {"lat": 47.94179413373048, "lng": 0.21307528020770408}, {
        "lat": 47.94185520416378,
        "lng": 0.2131396532240615
    }, {"lat": 47.9419126821527, "lng": 0.2132093906584487}, {
        "lat": 47.94195938297164,
        "lng": 0.21330058576495506
    }, {"lat": 47.942049192120294, "lng": 0.21337568761737205}, {
        "lat": 47.943550777972106,
        "lng": 0.21330058576495506
    }, {"lat": 47.94361184633011, "lng": 0.21323084833056782}, {
        "lat": 47.94362262309167,
        "lng": 0.21313428880603172
    }, {"lat": 47.94359029280023, "lng": 0.2130001783552871}, {
        "lat": 47.94354000119556,
        "lng": 0.21285533906848286
    }, {"lat": 47.94348970954196, "lng": 0.21269977094561912}, {
        "lat": 47.94343223330649,
        "lng": 0.2125227451506362
    }, {"lat": 47.94342864103964, "lng": 0.2124047279539809}, {
        "lat": 47.94347534048906,
        "lng": 0.21227598192126607
    }, {"lat": 47.94364417660809, "lng": 0.21224379541308738}, {
        "lat": 47.943805827694604,
        "lng": 0.21217942239672996
    }, {"lat": 47.94388844916587, "lng": 0.21209895612628318}, {
        "lat": 47.943917187037954,
        "lng": 0.2119809389296279
    }, {"lat": 47.943917187037954, "lng": 0.21178245546252583}, {
        "lat": 47.94391000257143,
        "lng": 0.2116161585036025
    }, {"lat": 47.94389922586976, "lng": 0.2114444971266494}, {
        "lat": 47.94386330351469,
        "lng": 0.21127283574969627
    }, {"lat": 47.943795050971254, "lng": 0.2111494541350112}, {"lat": 47.94373398282966, "lng": 0.21105825902850486}]],
    "name": "Piste Karting Pro",
    "logistics": "Essences, Karts, Personnels",
    "surface": "650",
    "description": "Piste avec Karts très puissants, réservé aux personnes qualifiés.",
    "provider": "524aaa51-09c1-48f1-85d3-ac878394e1ff",
    "service": "",
    "category": "pistes"
}, {
    "shape_id": 68,
    "type": "shape",
    "coordinates": [[{"lat": 47.94058670387456, "lng": 0.21117091180713035}, {
        "lat": 47.940453782518794,
        "lng": 0.21114408971698143
    }, {"lat": 47.940403487862625, "lng": 0.21117091180713035}, {
        "lat": 47.94018793878201,
        "lng": 0.21114408971698143
    }, {"lat": 47.940134051371444, "lng": 0.21117091180713035}, {
        "lat": 47.93977480053214,
        "lng": 0.21114408971698143
    }, {"lat": 47.93970654259044, "lng": 0.21113336088092186}, {
        "lat": 47.93964546961887,
        "lng": 0.21110117437274314
    }, {"lat": 47.93959517417633, "lng": 0.21105825902850486}, {
        "lat": 47.93926825260716,
        "lng": 0.21083831788928367
    }, {"lat": 47.93918562375138, "lng": 0.21080613138110496}, {
        "lat": 47.93910658733102,
        "lng": 0.21081149579913475
    }, {"lat": 47.93904551365068, "lng": 0.21088659765155174}, {
        "lat": 47.93898084732229,
        "lng": 0.21095097066790913
    }, {"lat": 47.93884432925227, "lng": 0.21092951299579002}, {
        "lat": 47.9387185886057,
        "lng": 0.21096706392199852
    }, {"lat": 47.938754514535944, "lng": 0.21142303945453025}, {
        "lat": 47.93877247749169,
        "lng": 0.21148741247088765
    }, {"lat": 47.938826366321486, "lng": 0.2115464210692153}, {
        "lat": 47.93900599534851,
        "lng": 0.2115142345610366
    }, {"lat": 47.939074254215114, "lng": 0.2114391327086196}, {
        "lat": 47.939358066431026,
        "lng": 0.21135866643817283
    }, {"lat": 47.93943350992247, "lng": 0.21135330202014305}, {
        "lat": 47.93949458314448,
        "lng": 0.21141231061847066
    }, {"lat": 47.93952332345925, "lng": 0.21149814130694725}, {
        "lat": 47.93951254584308,
        "lng": 0.2115893364134536
    }, {"lat": 47.93944788009884, "lng": 0.21164298059375145}, {
        "lat": 47.93935088133086,
        "lng": 0.21165907384784077
    }, {"lat": 47.93925388238087, "lng": 0.21166980268390037}, {
        "lat": 47.939164068375995,
        "lng": 0.21168053151995994
    }, {"lat": 47.93908143935376, "lng": 0.21169662477404927}, {
        "lat": 47.939027550789795,
        "lng": 0.21175026895434715
    }, {"lat": 47.938998810199415, "lng": 0.2118414640608535}, {
        "lat": 47.939002402774086,
        "lng": 0.21197557451159812
    }, {"lat": 47.939034735934904, "lng": 0.2121150493803725}, {
        "lat": 47.93906706907551,
        "lng": 0.2122062444868789
    }, {"lat": 47.93909940219589, "lng": 0.21232426168353413}, {
        "lat": 47.939102994763594,
        "lng": 0.2124154567900405
    }, {"lat": 47.93909580962796, "lng": 0.21253347398669578}, {
        "lat": 47.93907784678455,
        "lng": 0.21268367769152977
    }, {"lat": 47.93907066164545, "lng": 0.2128499746504531}, {
        "lat": 47.93905629136423,
        "lng": 0.21300554277331687
    }, {"lat": 47.93901318049659, "lng": 0.21311819555194236}, {
        "lat": 47.93902036564371,
        "lng": 0.213252306002687
    }, {"lat": 47.93904192107899, "lng": 0.21340250970752098}, {
        "lat": 47.93906706907551,
        "lng": 0.21348834039599754
    }, {"lat": 47.939113772465134, "lng": 0.2135419845762954}, {
        "lat": 47.93918562375138,
        "lng": 0.21359562875659324
    }, {"lat": 47.93925388238087, "lng": 0.213600993174623}, {
        "lat": 47.93946943535591,
        "lng": 0.21348834039599754
    }, {"lat": 47.93956643390148, "lng": 0.21341323854358055}, {
        "lat": 47.939656247207324,
        "lng": 0.21333277227313377
    }, {"lat": 47.93974606035714, "lng": 0.21326303483874656}, {
        "lat": 47.94066933049097,
        "lng": 0.2122706175032363
    }, {"lat": 47.9407196248885, "lng": 0.21214723588855125}, {
        "lat": 47.940744772068925,
        "lng": 0.2119809389296279
    }, {"lat": 47.94076273433316, "lng": 0.2118253708067641}, {"lat": 47.94076991923713, "lng": 0.21173954011828755}]],
    "name": "Piste Karting Amateur",
    "logistics": "Essences, Karts, Personnels",
    "surface": "525",
    "description": "Piste avec Karts moyennement puissants, réservé aux personnes amateurs.",
    "provider": "524aaa51-09c1-48f1-85d3-ac878394e1ff",
    "service": "",
    "category": "pistes"
}, {
    "shape_id": 69,
    "type": "shape",
    "coordinates": [[{"lat": 47.938541273311046, "lng": 0.21067202093036033}, {
        "lat": 47.938523310274945,
        "lng": 0.21055936815173484
    }, {"lat": 47.93849456940424, "lng": 0.21048426629931785}, {
        "lat": 47.93843708761489,
        "lng": 0.2104628086271987
    }, {"lat": 47.93837242052548, "lng": 0.21048963071734764}, {
        "lat": 47.93766466987117,
        "lng": 0.21067202093036033
    }, {"lat": 47.9376071871591, "lng": 0.2107149362745986}, {
        "lat": 47.93758563112562,
        "lng": 0.21079003812701558
    }, {"lat": 47.937592816471124, "lng": 0.2109026909056411}, {
        "lat": 47.93761437250163,
        "lng": 0.21102070810229637
    }, {"lat": 47.937653891867505, "lng": 0.21114408971698143}, {
        "lat": 47.937725745182675,
        "lng": 0.21124601365954732
    }, {"lat": 47.937955675120136, "lng": 0.21188437940509178}, {
        "lat": 47.938009564801305,
        "lng": 0.21195948125750877
    }, {"lat": 47.938067047065985, "lng": 0.21200776101977684}, {
        "lat": 47.93813889980697,
        "lng": 0.21199166776568745
    }, {"lat": 47.93821434507752, "lng": 0.2119809389296279}, {
        "lat": 47.93826464186339,
        "lng": 0.21199166776568745
    }, {"lat": 47.93830056810904, "lng": 0.21205067636401512}, {
        "lat": 47.93829338286191,
        "lng": 0.2121311426344619
    }, {"lat": 47.93826104923745, "lng": 0.21224379541308738}, {
        "lat": 47.93823949347655,
        "lng": 0.21234035493762352
    }, {"lat": 47.93826104923745, "lng": 0.21243155004412986}, {
        "lat": 47.93831134597787,
        "lng": 0.2125227451506362
    }, {"lat": 47.938455050680936, "lng": 0.21279633047015525}, {
        "lat": 47.93851971766699,
        "lng": 0.2128875255766616
    }, {"lat": 47.93860594018924, "lng": 0.2128928899946914}, {
        "lat": 47.93866701438885,
        "lng": 0.21283388139636375
    }, {"lat": 47.938692162567705, "lng": 0.2127319574537978}, {
        "lat": 47.93867419958405,
        "lng": 0.2126353979292617
    }, {"lat": 47.938623903196614, "lng": 0.21250128747851707}, {
        "lat": 47.93857001415577,
        "lng": 0.2124047279539809
    }, {"lat": 47.93857360676027, "lng": 0.21231889726550435}, {
        "lat": 47.93860594018924,
        "lng": 0.21217942239672996
    }, {"lat": 47.93866701438885, "lng": 0.2120882272902236}, {
        "lat": 47.93872449592256,
        "lng": 0.2120184898558364
    }, {"lat": 47.938753236665455, "lng": 0.2119058370772109}, {
        "lat": 47.93873168110979,
        "lng": 0.21182000638873433
    }, {"lat": 47.938677792181295, "lng": 0.21177709104449607}, {
        "lat": 47.93860953279122,
        "lng": 0.21178245546252583
    }, {"lat": 47.9385448659175, "lng": 0.21180927755267479}, {
        "lat": 47.93849456940424,
        "lng": 0.2118360996428237
    }, {"lat": 47.938440680228595, "lng": 0.21179854871661521}, {
        "lat": 47.938379605761625,
        "lng": 0.21171808244616844
    }, {"lat": 47.938365235288316, "lng": 0.21160006524951316}, {
        "lat": 47.938343679570885,
        "lng": 0.2114391327086196
    }, {"lat": 47.938322123844465, "lng": 0.21121382715136863}, {
        "lat": 47.93827182711452,
        "lng": 0.21112799646289207
    }, {"lat": 47.93819638192784, "lng": 0.21112263204486226}, {
        "lat": 47.93814608507556,
        "lng": 0.211154818553041
    }, {"lat": 47.938102973448956, "lng": 0.21120846273333885}, {
        "lat": 47.93809578817437,
        "lng": 0.2112835645857558
    }, {"lat": 47.93811734399516, "lng": 0.21138548852832176}, {
        "lat": 47.93814967770949,
        "lng": 0.2114444971266494
    }, {"lat": 47.93817482614, "lng": 0.2115142345610366}, {
        "lat": 47.93818560403506,
        "lng": 0.21159470083148338
    }, {"lat": 47.93814967770949, "lng": 0.21166443826587059}, {
        "lat": 47.93810656608587,
        "lng": 0.211653709429811
    }, {"lat": 47.93806345442633, "lng": 0.21159470083148338}, {
        "lat": 47.9379197486348,
        "lng": 0.21120846273333885
    }, {"lat": 47.93790178538278, "lng": 0.21111726762683247}, {
        "lat": 47.937944897177154,
        "lng": 0.21102607252032615
    }, {"lat": 47.93831493860031, "lng": 0.21091341974170064}, {
        "lat": 47.93836164266937,
        "lng": 0.21091878415973045
    }, {"lat": 47.9384047540803, "lng": 0.21098315717608787}, {
        "lat": 47.93842630977227,
        "lng": 0.2110743522825942
    }, {"lat": 47.93849456940424, "lng": 0.21147131921679832}, {
        "lat": 47.9385448659175,
        "lng": 0.21156251432330467
    }, {"lat": 47.93861312539294, "lng": 0.2115517854872451}, {
        "lat": 47.93867419958405,
        "lng": 0.2115195989790664
    }, {"lat": 47.93869575516371, "lng": 0.21143376829058982}, {
        "lat": 47.938663421790864,
        "lng": 0.2112621069136367
    }, {"lat": 47.93855564373539, "lng": 0.21079003812701558}]],
    "name": "Piste Karting Débutant",
    "logistics": "Essences, Karts, Personnels",
    "surface": "350",
    "description": "Piste avec Karts de location, réservé aux personnes débutantes et tout type d'âge.",
    "provider": "524aaa51-09c1-48f1-85d3-ac878394e1ff",
    "service": "",
    "category": "pistes"
}, {
    "type": "marker",
    "coordinates": {"lat": 47.94807747982196, "lng": 0.205672119310032},
    "iconUrl": "/markers/parkingMarker.png",
    "shape_id": 71
}, {
    "type": "marker",
    "coordinates": {"lat": 47.958209049715265, "lng": 0.20653314751764465},
    "iconUrl": "/markers/parkingMarker.png",
    "shape_id": 72
}, {
    "type": "marker",
    "coordinates": {"lat": 47.959703467780656, "lng": 0.21039365888798403},
    "iconUrl": "/markers/parkingMarker.png",
    "shape_id": 73
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95856828877381, "lng": 0.2119378634360958},
    "iconUrl": "/markers/parkingMarker.png",
    "shape_id": 74
}, {
    "type": "marker",
    "coordinates": {"lat": 47.957533673522974, "lng": 0.21354640984037057},
    "iconUrl": "/markers/parkingMarker.png",
    "shape_id": 75
}, {
    "type": "marker",
    "coordinates": {"lat": 47.961312792738994, "lng": 0.22109585429786807},
    "iconUrl": "/markers/parkingMarker.png",
    "shape_id": 76
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95807972304273, "lng": 0.22067105289473424},
    "iconUrl": "/markers/parkingMarker.png",
    "shape_id": 77
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95437222077322, "lng": 0.22189345468144775},
    "iconUrl": "/markers/parkingMarker.png",
    "shape_id": 78
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95339500533342, "lng": 0.22024201370639768},
    "iconUrl": "/markers/parkingMarker.png",
    "shape_id": 79
}, {
    "type": "marker",
    "coordinates": {"lat": 47.94028991066119, "lng": 0.2168083392107434},
    "iconUrl": "/markers/campingMarker.png",
    "shape_id": 80
}, {
    "type": "marker",
    "coordinates": {"lat": 47.94733010805995, "lng": 0.211315705198647},
    "iconUrl": "/markers/campingMarker.png",
    "shape_id": 81
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95053510691206, "lng": 0.21453399248103636},
    "iconUrl": "/markers/campingMarker.png",
    "shape_id": 82
}, {
    "type": "marker",
    "coordinates": {"lat": 47.949600934944854, "lng": 0.21893068598610735},
    "iconUrl": "/markers/campingMarker.png",
    "shape_id": 83
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95484645101738, "lng": 0.2176009542918722},
    "iconUrl": "/markers/campingMarker.png",
    "shape_id": 84
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95572304697993, "lng": 0.21903792241304568},
    "iconUrl": "/markers/campingMarker.png",
    "shape_id": 85
}, {
    "type": "marker",
    "coordinates": {"lat": 47.94488517466123, "lng": 0.20520400994428203},
    "iconUrl": "/markers/parkingMarker.png",
    "shape_id": 86
}, {
    "type": "marker",
    "coordinates": {"lat": 47.941048287844616, "lng": 0.20664167397626446},
    "iconUrl": "/markers/parkingMarker.png",
    "shape_id": 87
}, {
    "type": "marker",
    "coordinates": {"lat": 47.94867050119827, "lng": 0.2026977537752828},
    "iconUrl": "/markers/ballonMarker.png",
    "shape_id": 88
}, {
    "type": "marker",
    "coordinates": {"lat": 47.947942350876275, "lng": 0.2218723297119141},
    "iconUrl": "/markers/ballonMarker.png",
    "shape_id": 89
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95475887511937, "lng": 0.20906639092572735},
    "iconUrl": "/markers/village_service.png",
    "shape_id": 90
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95226834882291, "lng": 0.21185159683227542},
    "iconUrl": "/markers/raceCarMarker.png",
    "shape_id": 91
}, {
    "type": "marker",
    "coordinates": {"lat": 47.94121554952592, "lng": 0.21013498306274417},
    "iconUrl": "/markers/kartingMarker.png",
    "shape_id": 92
}, {
    "type": "marker",
    "coordinates": {"lat": 47.94559966929122, "lng": 0.2057147026062012},
    "iconUrl": "/markers/codeky_presta.png",
    "shape_id": 93
}, {
    "type": "marker",
    "coordinates": {"lat": 47.948627098910094, "lng": 0.20578122135702875},
    "iconUrl": "/markers/codeky_presta.png",
    "shape_id": 94
}, {
    "type": "marker",
    "coordinates": {"lat": 47.9416087357933, "lng": 0.20700645440228982},
    "iconUrl": "/markers/codeky_presta.png",
    "shape_id": 95
}, {
    "type": "marker",
    "coordinates": {"lat": 47.957930045738976, "lng": 0.20697212215964103},
    "iconUrl": "/markers/codeky_presta.png",
    "shape_id": 96
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95975750782311, "lng": 0.21105980869833732},
    "iconUrl": "/markers/codeky_presta.png",
    "shape_id": 97
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95819880648558, "lng": 0.21163916584555412},
    "iconUrl": "/markers/codeky_presta.png",
    "shape_id": 98
}, {
    "type": "marker",
    "coordinates": {"lat": 47.957709844913964, "lng": 0.2140316962868383},
    "iconUrl": "/markers/codeky_presta.png",
    "shape_id": 99
}, {
    "type": "marker",
    "coordinates": {"lat": 47.96120871509491, "lng": 0.22034016765628556},
    "iconUrl": "/markers/codeky_presta.png",
    "shape_id": 100
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95846487170803, "lng": 0.22084442295108533},
    "iconUrl": "/markers/codeky_presta.png",
    "shape_id": 101
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95358090378899, "lng": 0.22088956826337383},
    "iconUrl": "/markers/codeky_presta.png",
    "shape_id": 102
}, {
    "type": "marker",
    "coordinates": {"lat": 47.9546728155395, "lng": 0.22249889367230935},
    "iconUrl": "/markers/codeky_presta.png",
    "shape_id": 103
}, {
    "type": "marker",
    "coordinates": {"lat": 47.948229837797655, "lng": 0.22326707839965823},
    "iconUrl": "/markers/montgolfiere_presta.png",
    "shape_id": 104
}, {
    "type": "marker",
    "coordinates": {"lat": 47.94737691019997, "lng": 0.20201539990011955},
    "iconUrl": "/markers/montgolfiere_presta.png",
    "shape_id": 105
}, {
    "type": "marker",
    "coordinates": {"lat": 47.93971603000618, "lng": 0.21214342114035392},
    "iconUrl": "/markers/karting_presta.png",
    "shape_id": 106
}, {
    "type": "marker",
    "coordinates": {"lat": 47.93842080631041, "lng": 0.21218526361735715},
    "iconUrl": "/markers/karting_presta.png",
    "shape_id": 107
}, {
    "type": "marker",
    "coordinates": {"lat": 47.94343736209269, "lng": 0.21165418623240842},
    "iconUrl": "/markers/karting_presta.png",
    "shape_id": 108
}, {
    "type": "marker",
    "coordinates": {"lat": 47.94436782622631, "lng": 0.2124963998630847},
    "iconUrl": "/markers/karting_presta.png",
    "shape_id": 109
}, {
    "type": "marker",
    "coordinates": {"lat": 47.947650134109935, "lng": 0.21198248859946037},
    "iconUrl": "/markers/organisateurs_presta.png",
    "shape_id": 110
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95077581780417, "lng": 0.21545863148276115},
    "iconUrl": "/markers/organisateurs_presta.png",
    "shape_id": 111
}, {
    "type": "marker",
    "coordinates": {"lat": 47.94943949243716, "lng": 0.21972870823447013},
    "iconUrl": "/markers/organisateurs_presta.png",
    "shape_id": 112
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95592764069518, "lng": 0.21862363812033442},
    "iconUrl": "/markers/organisateurs_presta.png",
    "shape_id": 113
}, {
    "type": "marker",
    "coordinates": {"lat": 47.954957875350686, "lng": 0.21830177303854728},
    "iconUrl": "/markers/organisateurs_presta.png",
    "shape_id": 114
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95324914867069, "lng": 0.20809650421142578},
    "iconUrl": "/markers/organisateurs_presta.png",
    "shape_id": 115
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95350886292003, "lng": 0.2092026471927966},
    "iconUrl": "/markers/porsche_presta.png",
    "shape_id": 116
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95232691800457, "lng": 0.21031308172496213},
    "iconUrl": "/markers/porsche_presta.png",
    "shape_id": 117
}, {
    "type": "marker",
    "coordinates": {"lat": 47.951813182565786, "lng": 0.20919191835673703},
    "iconUrl": "/markers/organisateurs_presta.png",
    "shape_id": 118
}, {
    "type": "marker",
    "coordinates": {"lat": 47.950523475382724, "lng": 0.20792591570170774},
    "iconUrl": "/markers/organisateurs_presta.png",
    "shape_id": 119
}, {
    "type": "marker",
    "coordinates": {"lat": 47.94942386961144, "lng": 0.20705687998088254},
    "iconUrl": "/markers/organisateurs_presta.png",
    "shape_id": 120
}, {
    "type": "marker",
    "coordinates": {"lat": 47.94993395123936, "lng": 0.20698714254649533},
    "iconUrl": "/markers/organisateurs_presta.png",
    "shape_id": 121
}, {
    "type": "marker",
    "coordinates": {"lat": 47.950336498417194, "lng": 0.2069656848743762},
    "iconUrl": "/markers/organisateurs_presta.png",
    "shape_id": 122
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95095792424997, "lng": 0.20698714254649533},
    "iconUrl": "/markers/organisateurs_presta.png",
    "shape_id": 123
}, {
    "type": "marker",
    "coordinates": {"lat": 47.951547295450915, "lng": 0.2069227695301379},
    "iconUrl": "/markers/organisateurs_presta.png",
    "shape_id": 124
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95225511315994, "lng": 0.20705687998088254},
    "iconUrl": "/markers/organisateurs_presta.png",
    "shape_id": 125
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95272565582237, "lng": 0.20706224439891233},
    "iconUrl": "/markers/organisateurs_presta.png",
    "shape_id": 126
}, {
    "type": "marker",
    "coordinates": {"lat": 47.9531638672731, "lng": 0.20708906648906125},
    "iconUrl": "/markers/organisateurs_presta.png",
    "shape_id": 127
}, {
    "type": "marker",
    "coordinates": {"lat": 47.953606041797734, "lng": 0.2071051597431506},
    "iconUrl": "/markers/organisateurs_presta.png",
    "shape_id": 128
}, {
    "type": "marker",
    "coordinates": {"lat": 47.954019103031484, "lng": 0.20723390577586545},
    "iconUrl": "/markers/organisateurs_presta.png",
    "shape_id": 129
}, {
    "type": "marker",
    "coordinates": {"lat": 47.9544287793062, "lng": 0.20720708368571653},
    "iconUrl": "/markers/organisateurs_presta.png",
    "shape_id": 130
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95493175008685, "lng": 0.20734119413646115},
    "iconUrl": "/markers/organisateurs_presta.png",
    "shape_id": 131
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95355211866061, "lng": 0.20848917959483518},
    "iconUrl": "/markers/codeky_presta.png",
    "shape_id": 132
}, {
    "type": "marker",
    "coordinates": {"lat": 47.95383599213293, "lng": 0.20921874044688596},
    "iconUrl": "/markers/montgolfiere_presta.png",
    "shape_id": 133
}, {
    "type": "marker",
    "coordinates": {"lat": 47.954123338149, "lng": 0.20922410486491574},
    "iconUrl": "/markers/karting_presta.png",
    "shape_id": 134
}, {
    "type": "marker",
    "coordinates": {"lat": 47.94047756719348, "lng": 0.21750783917013908},
    "iconUrl": "/markers/organisateurs_presta.png",
    "shape_id": 135
}]


// TODO: api, shape [[{lat, lng} .. .. ..]] and marker {lat, lng}

module.exports = {
    shapes
}